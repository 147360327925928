import $ from "jquery";
import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// var swiper = new Swiper("#js-homeKhowSwiper", {
//   breakpoints: {
//     768: {
//       centeredSlides: false,
//     },
//   },
//   centeredSlides: false,
//   loop: true,
//   navigation: {
//     nextEl: "#js-homeKhowSwiper .swiper-button-next",
//     prevEl: "#js-homeKhowSwiper .swiper-button-prev",
//   },
//   pagination: {
//     clickable: true,
//     el: "#js-homeKhowSwiper .swiper-pagination",
//   },
//   slidesPerView: "auto",
//   spaceBetween: 0,
// });

// 共通設定
const swiperParam = {
  params: {
  },
};

// 共通設定の読み込み
Swiper.use(swiperParam);

// カルーセル
const cnt = $(".js-homeKhowSwiper").length;
let swiper;
if(cnt > 0){
  for(let i = 0; i < cnt; i++) {
    swiper = new Swiper('#js-homeKhowSwiper' + i + ' .swiper', {
      breakpoints: {
        768: {
          centeredSlides: false,
        },
      },
      centeredSlides: false,
      loop: true,
      navigation: {
        nextEl: ".js-homeKhowSwiper .swiper-button-next",
        prevEl: ".js-homeKhowSwiper .swiper-button-prev",
      },
      slidesPerView: "auto",
      spaceBetween: 0,
    });
  }
}

$('.js-homeKnowtab').on('click', (event) => {
  $('.js-homeKnowtab').removeClass('-active');
  $(event.currentTarget).addClass('-active');
  let index = $('.js-homeKnowtab').index(event.currentTarget);
  $('.js-homeKhowSwiper').hide();
  $('.js-homeKhowSwiper').eq(index).fadeIn();
   return false;
});
