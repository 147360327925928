import $ from "jquery";
import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

let swiper;
let slideCount;
let speed;

slideCount = $('#js-partnerGallerySwiperTop .swiper-slide').length;
speed = slideCount * 1500;
swiper = new Swiper("#js-partnerGallerySwiperTop", {
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  breakpoints: {
    768: {
      spaceBetween: 10,
    },
  },
  loop: true,
  loopedSlides: 1,
  slidesPerView: "auto",
  spaceBetween: 5,
  speed: speed,
});

slideCount = $('#js-partnerGallerySwiperBottom .swiper-slide').length;
speed = slideCount * 1500;
swiper = new Swiper("#js-partnerGallerySwiperBottom", {
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
    reverseDirection: true
  },
  breakpoints: {
    768: {
      spaceBetween: 10,
    },
  },
  loop: true,
  loopedSlides: 1,
  slidesPerView: "auto",
  spaceBetween: 5,
  speed: speed,
});
